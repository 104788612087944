import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <g id="R" transform="translate(27.000000, 28.000000)">
          <path
            d="M0.227983 33V0.272726H12.5007C15.0149 0.272726 17.1243 0.709516 18.8288 1.5831C20.544 2.45667 21.8384 3.68182 22.712 5.25852C23.5962 6.82457 24.0384 8.65163 24.0384 10.7397C24.0384 12.8384 23.5909 14.6602 22.696 16.2049C21.8118 17.739 20.5067 18.9268 18.7809 19.7685C17.055 20.5994 14.935 21.0149 12.4208 21.0149H3.67969V16.093H11.6218C13.092 16.093 14.2958 15.8906 15.2333 15.4858C16.1708 15.0703 16.8633 14.4684 17.3107 13.68C17.7688 12.881 17.9979 11.9009 17.9979 10.7397C17.9979 9.57848 17.7688 8.58771 17.3107 7.7674C16.8526 6.93643 16.1548 6.30788 15.2173 5.88175C14.2798 5.44496 13.0707 5.22656 11.5898 5.22656H6.15661V33H0.227983ZM17.1349 18.1705L25.2369 33H18.6211L10.663 18.1705H17.1349Z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default IconLogo;
